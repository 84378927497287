import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PieChart from "app/views/charts/echarts/PieChart";
import SimpleCard from "@gull/components/cards/SimpleCard";
import LineChart3 from "app/views/charts/echarts/LineChart3";
import ComparisonChart from "app/views/charts/echarts/ComparisonChart";
import { set } from 'lodash';
import { useNavigate } from "react-router-dom";


const DashboardView = () => {
  const navigate = useNavigate();

    const [data, setData] = useState(null);
    const [recentSignUp, setRecentSignUp] = useState([]);
    const [unSubmitted, setUnSubmitted] = useState([]);
    const [requireddata, setRequiredData] = useState([]);
    const [formdata, setFormData] = useState([]);

    const [campaigns, setCampaigns] = useState([]);
    const [totalapplicants, setTotalapplicants] = useState([]);

    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
    console.log("token",token)

    useEffect(() => {
      


      const fetchUsers = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL_strapi}/users?filters[type][$eq]=applicant&sort=createdAt:desc`,
            {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`, // JWT token for authentication
                'Content-Type': 'application/json',
              },
            }
          );
      
          if (!response.ok) {
            throw new Error('Failed to fetch users'); // Handle non-200 responses
          }
      
          const data = await response.json();
          setTotalapplicants(data.length)
          console.log('Fetched users:', data);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      };
      
      // Call the function when needed
      fetchUsers();
      
   
        const fetchCampaigns = async () => {
            try {
            
              const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/campaigns?sort=createdAt:desc`, {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${token}`, // JWT token of the logged-in user
                  'Content-Type': 'application/json',
                }
              });
      
              const data = await response.json();
              setCampaigns(data.data); // Store the campaigns in state
              console.log("campaign data ",data.data)
              if (data.data && data.data.length > 0) {
                const latestCampaign = data.data.reduce((latest, current) => new Date(current.attributes.createdAt) > new Date(latest.attributes.createdAt) ? current : latest);
                setSelectedCampaign(latestCampaign.id);
              } else {
                setSelectedCampaign('');
              }
            } catch (error) {
              console.error('Error fetching campaigns:', error);
            }
          };
      
          fetchCampaigns();

          async function getallapplicationfromcurrentcampaign() {
            try {
                // Fetch the latest campaign
                const campaignResponse = await fetch(
                    `${process.env.REACT_APP_BASE_URL_strapi}/campaigns?sort=createdAt:desc&pagination[limit]=1`,
                    {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`, // JWT token of the logged-in user
                            'Content-Type': 'application/json',
                        },
                    }
                );
        
                if (!campaignResponse.ok) {
                    throw new Error('Failed to fetch the latest campaign');
                }
        
                const campaignData = await campaignResponse.json();
                const latestCampaign = campaignData.data[0];
        
                if (!latestCampaign) {
                    console.error('No campaign found');
                    return; // If no campaign exists, handle appropriately
                }
        
                console.log('Latest campaign:', latestCampaign);
                localStorage.setItem("campaigin_id",latestCampaign.id);

                // Fetch total applications (without filtering by status)
                const totalApplicationsResponse = await fetch(
                  `${process.env.REACT_APP_BASE_URL_strapi}/applications/aggregateddata?campaignId=${latestCampaign.id} `,
                    {
                        method: 'POST',
                        headers: {
                         
                            'Content-Type': 'application/json',
                        },
                        
                    }
                );
     
        
                if (!totalApplicationsResponse.ok) {
                    throw new Error('Failed to fetch total applications');
                }
        
                const totalApplicationsData = await totalApplicationsResponse.json();
                console.log("totalApplicationsResponse11",totalApplicationsData)




                // const totalformdata = await fetch(
                //     `${process.env.REACT_APP_BASE_URL_strapi}/applications/getformdata?campaignId=${latestCampaign.id} `,
                //       {
                //           method: 'POST',
                //           headers: {
                           
                //               'Content-Type': 'application/json',
                //           },
                          
                //       }
                //   );
       
          
                //   if (!totalformdata.ok) {
                //       throw new Error('Failed to fetch total applications');
                //   }
          
                //   const totalformdataresult = await totalformdata.json();
                //   console.log("totalformdataresult",totalformdataresult)

                  

                //   setFormData(totalformdataresult)

                 console.log("totalApplicationsData",totalApplicationsData)
                   setRequiredData(totalApplicationsData);
               
       
            } catch (error) {
                console.error('Error in getallapplicationfromcurrentcampaign:', error);
            }
        }
        
        
          
        getallapplicationfromcurrentcampaign();


    }, []);
    const handleMaleClick = () => {
      navigate("/applicant/report/male");
    };
    const handleFeMaleClick = () => {
      navigate("/applicant/report/female");
    };
    const handleIncompleteClick = () => {
      navigate("/applicant/report/not-submitted");
    };
    const handleApprovedClick = () => {
      navigate("/applications/final-applications");
    };
    const handleRejectedClick = () => {
      navigate("/applications/rejected-applications");
    };
    const handleNewClick = () => {
      navigate("/applications/new-applications");
    };
    const handleShortlistClick = () => {
      navigate("/applications/approved-applications");
    };
          

    // Prepare the data for the PieChart
    let applicationByProvince = [
        { value: requireddata?.provinces?.punjab, name: "Punjab" },
        { value: requireddata?.provinces?.sindh, name: "Sindh" },
        { value: requireddata?.provinces?.kpk, name: "Khyber Khyber Pakhtunkhwa" },
        { value: requireddata?.provinces?.balochistan, name: "Balochistan" },
        { value: requireddata?.provinces?.islamabad, name: "Islamabad" },
        { value: requireddata?.provinces?.gilgitBaltistan, name: "Gilgit-Baltistan" },
        { value: requireddata?.provinces?.azadKashmir, name: "Azad Kashmir" }
    ];


    // For gender data, you can create separate arrays for male and female applicants
    let applicationByGender = [
        { value: requireddata?.totalMale, name: "Male" },
        { value: requireddata?.totalFemale, name: "Female" }
    ];
    let state = {
        cardList1: requireddata && [
            {
                icon: "i-Add-User",
                title: requireddata.totalnewapplications || 0,
                subtitle: "TOTAL New APPLICATIONS", 
                onClick: handleNewClick // Add the function here

            },
            {
                icon: "i-Add-User",
                title: requireddata.totalMale,
                subtitle: "TOTAL Applied Male",
                onClick: handleMaleClick // Add the function here
            },
            {
                icon: "i-Add-User",
                title: requireddata.totalFemale,
                subtitle: "TOTAL Applied Female",
                onClick: handleFeMaleClick // Add the function here

            },
            {
                icon: "i-Financial",
                title: requireddata.incomplete,
                subtitle: "INCOMPLETE",
                onClick: handleIncompleteClick // Add the function here

                
            },
            {
                icon: "i-Checkout-Basket",
                title: requireddata.final_stage,
                subtitle: "APPROVED",
                onClick: handleApprovedClick // Add the function here

            },
            {
                icon: "i-Money-2",
                title: requireddata.rejected,
                subtitle: "REJECTED",
                onClick: handleRejectedClick // Add the function here

            },
            {
                icon: "i-Money-2",
                title: requireddata.approved,
                subtitle: "SHORTLIST APPLICANT",
                onClick:handleShortlistClick
            },
            {
                icon: "i-Money-2",
                title: totalapplicants || 0,
                subtitle: "Total Sign Up",
            },
        ],

        newApplications: [
            {
                newApplicantList: [
                    {
                        applicantName: "Joh Doe",
                        applicantAge: 26,
                        applicantLocation: "Lahore",
                        applicantScore: 9,
                    },
                    {
                        applicantName: "Joh Doe",
                        applicantAge: 26,
                        applicantLocation: "Lahore",
                        applicantScore: 9,
                    }, {
                        applicantName: "Joh Doe",
                        applicantAge: 26,
                        applicantLocation: "Lahore",
                        applicantScore: 9,
                    },

                ],
            },

        ],
    };

    // const getUserStatusClass = (status) => {
    //     switch (status) {
    //         case "active":
    //             return "badge-success";
    //         case "inactive":
    //             return "badge-warning";
    //         case "pending":
    //             return "badge-primary";
    //         default:
    //             break;
    //     }
    // };

    let {
        cardList1 = [],
        newApplications = [],
    } = state;
    const handleCampaignChange = (event) => {
        const selectedCampaignId = event.target.value;
        setSelectedCampaign(selectedCampaignId);
        // Pass the selected campaign ID to another function here
        console.log("Selected Campaign ID:", selectedCampaignId);
        // Call the function and pass the campaign ID
        localStorage.setItem("campaigin_id",selectedCampaignId);
        handleCampaignSelection(selectedCampaignId);

      };
    


      async function handleCampaignSelection(campaignId) {
        try {
            // Fetch the latest campaign
         
    
            if (!campaignId) {
                console.error('No campaign found');
                return; // If no campaign exists, handle appropriately
            }
    
            console.log('Latest campaign:', campaignId);
    
            // Fetch total applications (without filtering by status)
            const totalApplicationsResponse = await fetch(
                `${process.env.REACT_APP_BASE_URL_strapi}/applications/aggregateddata?campaignId=${campaignId} `,
                  {
                      method: 'POST',
                      headers: {
                       
                          'Content-Type': 'application/json',
                      },
                      
                  }
              );
   
      
              if (!totalApplicationsResponse.ok) {
                  throw new Error('Failed to fetch total applications');
              }
      
              const totalApplicationsData = await totalApplicationsResponse.json();
              console.log("totalApplicationsResponse11",totalApplicationsData)
            //   const totalformdata = await fetch(
            //     `${process.env.REACT_APP_BASE_URL_strapi}/applications/getformdata?campaignId=${campaignId} `,
            //       {
            //           method: 'POST',
            //           headers: {
                       
            //               'Content-Type': 'application/json',
            //           },
                      
            //       }
            //   );
   
      
            //   if (!totalformdata.ok) {
            //       throw new Error('Failed to fetch total applications');
            //   }
      
            //   const totalformdataresult = await totalformdata.json();
            //   console.log("totalformdataresult",totalformdataresult)

              

            //   setFormData(totalformdataresult)
                 setRequiredData(totalApplicationsData);
    
            // Update state
    
        } catch (error) {
            console.error('Error in getallapplicationfromcurrentcampaign:', error);
        }
    }



    
    return (
        <div className="main-content-wrap d-flex flex-column sidenav-open">


            {/* <Breadcrumb
                routeSegments={[
                    { name: "Dashboard", path: "/dashboard" },
                    { name: "Version 1" },
                ]}
            ></Breadcrumb> */}
            <div>
      <label htmlFor="campaigns">Select a Campaign: </label>
      <select id="campaigns" onChange={handleCampaignChange} value={selectedCampaign}>
        <option value="">--Select a Campaign--</option>
        {campaigns?.map((campaign) => (
          <option key={campaign.id} value={campaign.id}>
            {campaign.name}
          </option>
        ))}
      </select>
    </div>
            <div className="row">
            {state.cardList1 &&
              state.cardList1.map((card, index) => (
                <div
                  key={index}
                  className="col-lg-3 col-md-6 col-sm-6"
                  onClick={card.onClick || null} // Use card's onClick if defined
                  style={{ cursor: card.onClick ? "pointer" : "default" }} // Show pointer only for clickable cards
                >
                  <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                    <div className="card-body">
                      <div className="content custom w-100 p-3">
                        <h1 className="text-primary text-30 text-right mb-2 text-capitalize">
                          {card.title}
                        </h1>
                        <h5 className="text-muted text-left mt-2 mb-0 text-uppercase">
                          {card.subtitle}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

            <div className="row">
                {/* <div className="col-lg-8 col-md-12">
                    <SimpleCard title="This Year Applications" className="mb-4">
                        <ComparisonChart height="260px"></ComparisonChart>
                    </SimpleCard>
                </div> */}
                <div className="col-lg-6 col-sm-12">
                    <SimpleCard title="Application by Province" className="mb-4">
                        <PieChart height="260px" data={applicationByProvince}></PieChart>
                    </SimpleCard>
                </div>
                <div className="col-lg-6 col-sm-12">
                    <SimpleCard title="Application by Gender" className="mb-4">
                        <PieChart height="260px" data={applicationByGender}></PieChart>
                    </SimpleCard>
                </div>
                {/* <div className="col-lg-6 col-sm-12">
                    <SimpleCard title="Apllication by Province" className="mb-4">
                        <PieChart height="260px"></PieChart>
                    </SimpleCard>
                </div> */}
            </div>

            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card mb-4">
                                <div className="card-body p-0 " style={{ overflow: "hidden" }}>
                                    <div className="card-title border-bottom d-flex align-items-center bg-primary m-0 p-3">
                                        <h3 className="mb-0 text-white">New Applications</h3>
                                        <span className="flex-grow-1"></span>
                                        <span className="badge badge-pill badge-warning">
                                            Updated daily
                                        </span>
                                    </div>
                                    {recentSignUp && recentSignUp.map((item, index) => (
                                        <div
                                            key={index}
                                            className="d-flex row border-bottom justify-content-between p-3"
                                        >

                                            <div key={item.id} >


                                                <table style={{ borderCollapse: 'collapse' }} className='w-100'>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ border: 'none' }}><h5 className="m-0">{item?.name}</h5></td>
                                                            <td style={{ border: 'none', textAlign: "right" }}><span className="text-small text-muted">{item?.id}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ border: 'none' }}> <p>{item?.address}</p> </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* {newApplicantList.map((item, i) => (
                                                <div key={i} className="col-6">
                                                    <span className="text-small text-muted">
                                                        {item.applicantName}
                                                    </span>
                                                    <h5 className="m-0">{item.applicantAge}</h5>
                                                </div>
                                            ))} */}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card mb-4">
                                <div className="card-body p-0" style={{ overflow: "hidden" }}>
                                    <div className="card-title border-bottom d-flex align-items-center bg-primary  m-0 p-3">
                                        <h3 className="mb-0 text-white">Publish Applications</h3>
                                        <span className="flex-grow-1"></span>
                                        <span className="badge badge-pill badge-warning">
                                            Updated daily
                                        </span>
                                    </div>
                                    {unSubmitted && unSubmitted.map((item, index) => (
                                        <div
                                            key={index}
                                            className="d-flex row border-bottom justify-content-between p-3"
                                        >
                                            <div key={item.id} >


                                                <table style={{ borderCollapse: 'collapse' }} className='w-100'>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ border: 'none' }}><h5 className="m-0">{item?.name}</h5></td>
                                                            <td style={{ border: 'none', textAlign: "right" }}><span className="text-small text-muted">{item?.status}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ border: 'none' }}> <p>{item?.address}</p> </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-md-12">
                    <div className="card mb-4">
                        <div className="card-body card-title mb-0">
                            <h3 className="m-0">Last 20 Day Applications</h3>
                        </div>
                        <LineChart3 data={formdata} height="360px"></LineChart3>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="card mb-4">

                    </div>
                </div>

            </div>
        </div>
    );
};

export default DashboardView;
