import React from "react";
import DashboardHeader from "./DashboardHeader";
import { useNavigate } from "react-router-dom";

const Results = () => {
  const navigate = useNavigate();

  const redirect = (e) => {
    navigate("/application/instructions");

  };  return (
    <div>
      {/* Header Start */}
      <div className="mb-5">
        <DashboardHeader />
      </div>

      <div className="container justify-content-center m-auto mb-5">
        <div className="row justify-content-center">

          <div className="col-sm-12 col-md-8 mb-4 text-center">
            <div className="card d-flex justify-content-center  py-5">
              {/* filter: "drop-shadow(rgb(112, 191, 106) 0px 0px 0.2rem)",  */}
              <svg className="mb-4 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: "150px", height: "150px" }}>
                <path fill="#70bf6a" d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z" />
              </svg>

              <h4>Your Application Has been submitted successfully.</h4>
            </div>
            <div className="card-footer">
                      <div className="mc-footer">
                        <div className="row text-center">
                          <div className="col-lg-12 ">
                            <button type="submit" className="btn btn-primary text-white m-1"  onClick={redirect}
                              >
                              View Application
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
          </div>
        </div>


      </div>
    </div>
  );
};

export default Results;
