import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AdminNav from "./AdminNav";
import DashboardHeader from "./DashboardHeader";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import SimpleCard from "@gull/components/cards/SimpleCard";
import { useNavigate } from "react-router-dom";
import { NotificationContainer, NotificationManager, } from "react-notifications";

const ViewApplication = () => {
    const { id } = useParams();
    const [applicationid, setApplicationId] = useState([]);
    const navigate = useNavigate();
    const [financeamount, setFinanceAmount] = useState({
      finance_amount: 0, // Initial state for financial_need
      // Add other fields here if necessary
    });
    const [education, setEducation] = useState([]);
    const [resume1, setResume1] = useState([]);
    const [resume2, setResume2] = useState([]);
    const [resume3, setResume3] = useState([]);
    const [resume4, setResume4] = useState([]);
    const [resume5, setResume5] = useState([]);
    const [resume6, setResume6] = useState([]);
    const [resume7, setResume7] = useState([]);
    const [acedemicuploads, setAcedemicUploads] = useState([]);
    const [financedetail, setFinanceDetail] = useState({});
    const [personalstatement, setPersonalStatement] = useState({});
    const [applicationstatus, setApplicationStatus] = useState({});
    const [fundsrequested, setFundsRequested] = useState({});

    const [fields, setFields] = useState({});
    const [loading, setLoading] = useState(false);
    const [evaluationId, setEvaluationId] = useState("");

    
    const [data, setData] = useState({
      academic_performance: 0,
      financial_need: 0,
      personal_statement: 0,
        totalScore: 0,
        next_stage: false, // Store accept/reject as boolean

      });
    const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
    console.log("token",token)
    // const auth = JSON.parse(localStorage.getItem("auth_user_info"));

    // const applicantID = auth ? auth.id : null;

     // Handle input changes and update state


     const handleInputChangefinance = (event) => {
      console.log("event".event)
      const { name, value } = event.target; // Get name and value from the input field
      setFinanceAmount((prevState) => ({
        ...prevState,
        [name]: value, // Dynamically update the field in the state
      }));
    };

     useEffect(() => {
        const fetchData = async () => {
            fetch(`${process.env.REACT_APP_BASE_URL_strapi}/users/me?populate[evaluations][filters][application][documentId][$eq]=${id}`, { 
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${token}` // JWT token of the logged-in user
                }
              })
                .then(response => response.json())
                .then(evaluation => {
                  console.log('User with filtered evaluations:', evaluation);
              
                  const evaluations = evaluation.evaluations[0]; // Extract the filtered evaluations from the populated response
                  if (evaluations) {
                    evaluations.totalScore =
                    evaluations.academic_performance +
                    evaluations.financial_need +
                    evaluations.personal_statement;
                    setData(evaluations)
                    setEvaluationId(evaluations.documentId); // Store the evaluation ID in the state for future updates

                    // Assuming evaluations are returned and are related to applications
                 
                  } else {
                    console.log('No evaluations related to the specified application found for the user');
                  }
                })
                .catch(error => {
                  console.error('Error fetching user and filtered evaluations:', error);
                });
              
        };
            // Fetch the latest campaign first
    
    
        fetchData();
      }, []);




  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    // If the input is a checkbox, store the checked state
    if (type === "checkbox") {
      setData((prevData) => ({
        ...prevData,
        [name]: checked, // Checkbox true or false
      }));
    } else {
      // Handle numeric input
      const parsedValue = value === "" ? 0 : parseInt(value, 10);

      if (parsedValue > 10) {
        alert("Score cannot be more than 10");
        return;
      }

      setData((prevData) => {
        const newData = {
          ...prevData,
          [name]: isNaN(parsedValue) ? 0 : parsedValue,
        };

        const totalScore = newData.academic_performance + newData.financial_need + newData.personal_statement;

        return {
          ...newData,
          totalScore, // Update the total score
        };
      });
    }
}
const handleButtonClick1 = async (event) => {
    if (!id || !token) {
      alert("Application ID or Token missing!");
      return;
    }
  
    try {
      // Check if an evaluation ID exists (from the useEffect)
      if (evaluationId) {
        // If evaluation ID exists, update the existing evaluation
        const updateResponse = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/evaluations/${evaluationId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Pass the token for authentication
          },
          body: JSON.stringify({
            data: {
              academic_performance: data.academic_performance,
              financial_need: data.financial_need,
              personal_statement: data.personal_statement,
              next_stage: data.next_stage,
              application: id, // Send the application ID
            },
          }),
        });
  
        if (updateResponse.ok) {
            const responseData = await updateResponse.json();
            console.log("Evaluation created successfully:", responseData);
      
            // After creating the evaluation, fetch all evaluations related to the application
            const fetchEvaluationsResponse = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/evaluations?filters[application][documentId][$eq]=${id}&populate=*`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`, // Pass the token for authentication
              },
            });
      
            if (fetchEvaluationsResponse.ok) {
              const evaluationsData = await fetchEvaluationsResponse.json();
              console.log("All evaluations fetched successfully:", evaluationsData);
             
              // Map through the evaluations and count the number of next_stage true/false
                const result = evaluationsData.data.reduce(
                    (acc, val) => {
                    if (val.next_stage == true) {
                        acc.trueCount++;
                    } else if(val.next_stage == false) {
                        acc.falseCount++;
                    }else{
                      
                    }
                    return acc;
                    },
                    { trueCount: 0, falseCount: 0 }
                );
                
                // Check the conditions and return the appropriate value
                let finalDecision = "none"; // Default value
                if (result.trueCount >= 2) {
                    let  outgoingData = {
                      application_status:'approved'
                      }           
                     try { 
                      console.log(" did it come here ")
                        const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${applicationid}`, {
                          method: 'PUT', 
                          headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json', // Ensure content-type is set to JSON
                          },
                          body: JSON.stringify({ data: outgoingData }) // Send the data wrapped in a 'data' object
                        });
                    
                        if (!response.ok) {
                          throw new Error('Failed to submit form');
                        }
                        navigate('/applications/new-applications');

                        const data = await response.json();
                        console.log(data);
                      } catch (error) {
                        console.error('Error:', error);
                      }



                    finalDecision = "yes";
                } else if (result.falseCount >= 2) {
                  let  outgoingData = {
                    application_status:'rejected'
                  }
                    try {
                        const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${applicationid}`, {
                          method: 'PUT', 
                          headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json', // Ensure content-type is set to JSON
                          },
                          body: JSON.stringify({ data: outgoingData }) // Send the data wrapped in a 'data' object
                        });
                    
                        if (!response.ok) {
                          throw new Error('Failed to submit form');
                        }
                        navigate('/applications/new-applications');

                        const data = await response.json();
                        console.log(data);
                      } catch (error) {
                        console.error('Error:', error);
                      }

                    finalDecision = "no";
                }
                navigate('/applications/new-applications');

                console.log("Final decision:", finalDecision);
              // Handle the evaluations data (for example, update the state or display the data)
              // You can set the data in a state or manipulate it as needed
      
            } else {
              console.error("Failed to fetch evaluations:", fetchEvaluationsResponse);
              alert("Failed to fetch evaluations. Please try again.");
            }
          } else {
            console.error("Failed to create evaluation:", updateResponse);
            alert("Failed to create evaluation. Please try again.");
          }
      } else {
        // If no evaluation ID, create a new evaluation
        const createResponse = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/evaluations`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Pass the token for authentication
          },
          body: JSON.stringify({
            data: {
              academic_performance: data.academic_performance,
              financial_need: data.financial_need,
              personal_statement: data.personal_statement,
              next_stage: data.next_stage,
              application: id, // Send the application ID
            },
          }),
        });
  
        if (createResponse.ok) {
            const responseData = await createResponse.json();
            console.log("Evaluation created successfully:", responseData);
          
      
            // After creating the evaluation, fetch all evaluations related to the application
            const fetchEvaluationsResponse = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/evaluations?filters[application][documentId][$eq]=${id}&populate=*`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`, // Pass the token for authentication
              },
            });
      
            if (fetchEvaluationsResponse.ok) {
              const evaluationsData = await fetchEvaluationsResponse.json();
              console.log("All evaluations fetched successfully:", evaluationsData);
      
              // Handle the evaluations data (for example, update the state or display the data)
              // You can set the data in a state or manipulate it as needed
                  
              // Map through the evaluations and count the number of next_stage true/false
              const result = evaluationsData.data.reduce(
                (acc, val) => {
                if (val.next_stage == true) {
                    acc.trueCount++;
                } else if(val.next_stage == false) {
                    acc.falseCount++;
                }else{
                  
                }
                return acc;
                },
                { trueCount: 0, falseCount: 0 }
            );
            
            // Check the conditions and return the appropriate value
            let finalDecision = "none"; // Default value
            if (result.trueCount >= 2) {
                let  outgoingData = {
                  application_status:'approved'
                  }           
                 try {
                    const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${applicationid}`, {
                      method: 'PUT', 
                      headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json', // Ensure content-type is set to JSON
                      },
                      body: JSON.stringify({ data: outgoingData }) // Send the data wrapped in a 'data' object
                    });
                
                    if (!response.ok) {
                      throw new Error('Failed to submit form');
                    }
                    navigate('/applications/new-applications');

                    const data = await response.json();
                    console.log(data);
                  } catch (error) {
                    console.error('Error:', error);
                  }



                finalDecision = "yes";
            } else if (result.falseCount >= 2) {
              let  outgoingData = {
                application_status:'rejected'
              }
                try {
                    const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${applicationid}`, {
                      method: 'PUT', 
                      headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json', // Ensure content-type is set to JSON
                      },
                      body: JSON.stringify({ data: outgoingData }) // Send the data wrapped in a 'data' object
                    });
                
                    if (!response.ok) {
                      throw new Error('Failed to submit form');
                    }
                    navigate('/applications/new-applications');

                    const data = await response.json();
                    console.log(data);
                  } catch (error) {
                    console.error('Error:', error);
                  }

                finalDecision = "no";
            }
            navigate('/applications/new-applications');

            console.log("Final decision:", finalDecision);
          // Handle the evaluations data (for example, update the state or display the data)
          // You can set the data in a state or manipulate it as needed

      
            } else {
              console.error("Failed to fetch evaluations:", fetchEvaluationsResponse);
              alert("Failed to fetch evaluations. Please try again.");
            }
          } else {
            console.error("Failed to create evaluation:", createResponse);
            alert("Failed to create evaluation. Please try again.");
          }
      }
    } catch (error) {
      console.error("Error saving evaluation:", error);
      alert("An error occurred while saving the evaluation.");
    }
  };
  
const saveFinance = async () => {
  try {
    console.log("finance amount ", financeamount.finance_amount);

    const createResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL_strapi}/finance-amounts`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Pass the token for authentication
        },
        body: JSON.stringify({
          data: {
            amount: financeamount.finance_amount,
            application: id, // Send the application ID
          },
        }),
      }
    );

    if (createResponse.ok) {
      const responseData = await createResponse.json();
      console.log("finance added successfully:", responseData);

      // Show success notification
      NotificationManager.success("Funds added sucessfully");


    } else {
      const errorData = await createResponse.json();
      console.error("Failed to save finance amount:", errorData);

      // Show error notification
      NotificationManager.error(
        "Failed to save finance amount. Please try again.",
        "Error"
      );
    }
  } catch (error) {
    console.error("Error occurred while saving finance amount:", error);

    // Show error notification for unexpected issues
    NotificationManager.error(
      "An unexpected error occurred. Please try again later.",
      "Error"
    );
  }
};

  
    const fetchData = async () => {
        try {
       
            setLoading(false);
            const response1 = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/academic-details?filters[application][documentId][$eq]=${id}&populate=*
            `, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Your JWT token
                    'Content-Type': 'application/json'
                }
            });
            const response2 = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/academic-uploads?filters[application][documentId][$eq]=${id}&populate=*
            `, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Your JWT token
                    'Content-Type': 'application/json'
                }
            });
            const response3 = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/documents-uploads?filters[application][documentId][$eq]=${id}&populate=*
            `, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Your JWT token
                    'Content-Type': 'application/json'
                }
            });
            const response4 = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/finance-details?filters[application][documentId][$eq]=${id}&populate=*
            `, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Your JWT token
                    'Content-Type': 'application/json'
                }
            });
            const response5 = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/personal-statements?filters[application][documentId][$eq]=${id}&populate=*
            `, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Your JWT token
                    'Content-Type': 'application/json'
                }
            });
            const response6 = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/finance-amounts?filters[application][documentId][$eq]=${id}&populate=*
            `, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Your JWT token
                    'Content-Type': 'application/json'
                }
            });
            
            const data1 = await response1.json();
            const data2 = await response2.json();
            const data3 = await response3.json();
            const data4 = await response4.json();
            const data5 = await response5.json();
            const data6 = await response6.json();

           
            setApplicationStatus(data1?.data[0]?.application?.application_status)
            console.log("hope5",data1);
            console.log("hope6",data1?.data[0]?.application?.id);

            setFinanceAmount((prevState) => ({
              ...prevState, // Keep other fields intact
              finance_amount: data6?.data[0]?.amount, // Set or update the 'amount' field
            }));
            setFundsRequested(data4?.data[0]?.funds_request)
            setFields(data1?.data[0]?.application)
            setApplicationId(data1?.data[0]?.application?.id)
              setEducation(data1.data);
              setResume1({url:data3?.data[0]?.attested_applicant_cnic_back.url})
              setResume2({url:data3?.data[0]?.attested_applicant_cnic_front.url})
              setResume3({url:data3?.data[0]?.attested_father_guardian_cnic_back.url})
              setResume4({url:data3?.data[0]?.attested_father_guardian_cnic_front.url})
              setResume5({url:data3?.data[0]?.electricity_bill.url})
              setResume6({url:data3?.data[0]?.paid_fee_challan.url})
              setResume7({url:data3?.data[0]?.student_id_card.url})
              setAcedemicUploads({url:data2?.data[0]?.marksheet?.url})
              setFinanceDetail(data4?.data[0])
              setPersonalStatement(data5?.data[0])
        setTimeout(() => {
            console.log("fields",fields);
            console.log("education",education);
            console.log("resume1",resume1);
            console.log("resume2",resume2);
            console.log("resume3",resume3);
            console.log("resume4",resume4);
            console.log("resume5",resume5);
            console.log("resume6",resume6);

            
        }, 4000);
        } catch (error) {
            console.error('Error fetching personal information data:', error);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, []);

    const handleButtonClick = async (event) => {
        const eventName = event.target.innerText.toLowerCase();

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/applicant/score`, {
            method: 'PUT',
            headers: {
                'Accept': '*/*',
                'Event': eventName,
                'SessionKey': '123',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                applicantId: parseInt(id),
                graceScore: parseInt(fields.graceScore),
                score: parseInt(fields.score)
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        // console.log(data);
        fetchData();
    }




    // Email and contact
    let emails = fields && fields.email ? fields.email.split(', ') : ['N/A'];
    let contacts = fields && fields.contact ? fields.contact.split(', ') : ['N/A', 'N/A', 'N/A'];

    // Remove 'L:' from the landline number
    if (contacts[2]) {
        contacts[2] = contacts[2].replace('L:', '');
    }
    // Text Formatting
    function formatText(text) {
        let lines = text.split('\n');
        let formattedText = lines.map(line => `<p>${line}</p>`).join('');
        return formattedText;
    }

    let coverLettertext = fields && fields.coverLetter ? formatText(fields.coverLetter) : 'N/A';
    let formattedCoverLetter = formatText(coverLettertext);

    let skillsText = fields && fields.skills ? fields.skills : 'N/A';
    let skillsTextFormatted = formatText(skillsText);

    // let fundingText = fields && fields.fundingRequest ? fields.fundingRequest : 'N/A';
    // let fundingTextFormatted = formatText(fundingText);

    let fundingStatus = fields && fields.fundingRequest
        ? (fields.fundingRequest === "1" ? "Funding is required" : "Funding is not required")
        : "No fields object or funding request field";

    // Styles:
    const styles = {
        display: 'inline-block',
        maxWidth: '100px',
        fontWeight: 700,
        height: '40px',
        fontSize: '12pt',
        lineHeight: 1.2,
    };
    let displayStyle = { display: styles.display };
    return (
        <div className="app-admin-wrap layout-sidebar-large">
            <DashboardHeader />
            <AdminNav />
            <div className="main-content-wrap d-flex flex-column sidenav-open">
                {
                    loading ? (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                        </div>

                    ) : (
                        <div className="mb-4">
                          {applicationstatus !== 'final_stage' ? (

                            <SimpleCard title={`EVALUATION`} className="mb-4">
                            <Row className="justify-content-center">
                                <Col lg={6} md={6} sm={12} xs={12}>
                                <div className="row justify-content-between mb-2">
                                    <h5 style={displayStyle} className="text-capitalize mb-1 col-6">
                                    Academic Performance:
                                    </h5>
                                    <input
                                    style={styles}
                                    type="number"
                                    name="academic_performance"
                                    value={data.academic_performance || 0}
                                    onChange={handleInputChange}
                                    className="form-control col-6"
                                    min="0"
                                    max="10"
                                    />
                                </div>

                                <div className="row justify-content-between mb-2">
                                    <h5 style={displayStyle} className="text-capitalize mb-1 col-6">
                                    Financial Need:
                                    </h5>
                                    <input
                                    style={styles}
                                    type="number"
                                    name="financial_need"
                                    value={data.financial_need || 0}
                                    onChange={handleInputChange}
                                    className="form-control col-6"
                                    min="0"
                                    max="10"
                                    />
                                </div>

                                <div className="row justify-content-between mb-2">
                                    <h5 style={displayStyle} className="text-capitalize mb-1 col-6">
                                    Personal Statement/Interview:
                                    </h5>
                                    <input
                                    style={styles}
                                    type="number"
                                    name="personal_statement"
                                    value={data.personal_statement || 0}
                                    onChange={handleInputChange}
                                    className="form-control col-6"
                                    min="0"
                                    max="10"
                                    />
                                </div>

                                <div className="row justify-content-between mb-4">
                                    <h5 style={displayStyle} className="text-capitalize mb-1 col-6">
                                    Total Score:
                                    </h5>
                                    <input
                                    style={styles}
                                    type="number"
                                    value={data.totalScore}
                                    className="form-control col-6"
                                    disabled
                                    />
                                </div>

                                <div className="row justify-content-between mb-4">
                                    <h5 style={displayStyle} className="text-capitalize mb-1 col-6">
                                    Accept/Reject:
                                    </h5>
                                    <input
                                    type="checkbox"
                                    name="next_stage"
                                    checked={data.next_stage}
                                    onChange={handleInputChange}
                                    className="form-check-input col-6"
                                    />
                                    <span>{data.next_stage ? "Accepted" : "Rejected"}</span>
                                </div>

                                <div className="text-center">
                                    <button
                                    style={styles}
                                    type="button"
                                    className="m-1 text-capitalize btn btn-outline-primary"
                                    onClick={handleButtonClick1}
                                    >
                                    Save
                                    </button>
                                </div>
                                </Col>
                            </Row>
                            </SimpleCard>
                            ) : (
                              <SimpleCard title={`Finance`} className="mb-4">
                                  <Row className="justify-content-center">
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                      <div className="row justify-content-between mb-2">
                                        <h5 style={displayStyle} className="text-capitalize mb-1 col-6">
                                        Requested Funds:
                                        </h5>
                                        <input
                                          style={styles}
                                          type="number"
                                          name="financial_need"
                                          value={fundsrequested || 0}
                                          disabled
                                          className="form-control col-6"
                                          min="0"
                                          max="10"
                                        />
                                      </div>

                                      <div className="row justify-content-between mb-2">
                                        <h5 style={displayStyle} className="text-capitalize mb-1 col-6">
                                        Allocated Funds
                                        </h5>
                                        <input
                                          style={styles}
                                          type="number"
                                          name="finance_amount"
                                          value={financeamount.finance_amount || 0}
                                          onChange={handleInputChangefinance}
                                          className="form-control col-6"
                                          min="0"
                                          max="10"
                                        />
                                      </div>
                                      <div className="text-center">
                                    <button
                                    style={styles}
                                    type="button"
                                    className="m-1 text-capitalize btn btn-outline-primary"
                                    onClick={saveFinance}
                                    >
                                    Save
                                    </button>
                                </div>
                                    </Col>
                                  </Row>
                                </SimpleCard>
                              )}

                            <Row>
                                <Col lg={12} md={12} sm={12} xs={12}>

                                    <SimpleCard title={`${fields && fields.name ? fields.name : 'N/A'}`}>

                                        <Tabs defaultActiveKey="home" id="home">
                                            <Tab eventKey="home" title="BASIC INFROMATION">
                                                <div className="row">
                                                    <p className='col-6'><strong>Name:</strong> {fields && fields.name ? fields?.name : 'N/A'}</p>
                                                    {/* <div className='col-6 row'> */}
                                                    <p className='col-6'><strong>Email:</strong> {fields && fields.email ? fields?.email : 'N/A'}</p>
                                                    {/* </div> */}
                                                    {/* <div className='col-6 row'> */}

                                                    <p className='col-6'><strong>Contact:</strong>  {fields && fields.phone_number ? fields.phone_number : 'N/A'}</p>
                                                    {/* </div> */}
                                                    <p className='col-6'><strong>Gender:</strong> {fields && fields.gender ? fields.gender : 'N/A'}</p>
                                                   
                                                    <p className='col-6'><strong>Date of Birth:</strong> {fields && fields.date_of_birth ? fields.date_of_birth : 'N/A'}</p>
                                                    <p className='col-6'><strong> Permenent Address:</strong> {fields && fields.permenent_address ? fields.permenent_address : 'N/A'}</p>
                                                    <p className='col-6'><strong>Temporary Address:</strong> {fields && fields.temporary_address ? fields.temporary_address : 'N/A'}</p>
                                                    <p className='col-6'><strong>Father Name:</strong> {fields && fields.father_name ? fields.father_name : 'N/A'}</p>
                                                    <p className='col-6'><strong>Applicant CNIC:</strong> {fields && fields.applicant_cnic ? fields.applicant_cnic : 'N/A'}</p>
                                                    <p className='col-6'><strong>Parent CNIC:</strong> {fields && fields.parent_cnic ? fields.parent_cnic : 'N/A'}</p>
                                                    <p className='col-6'><strong>Parent Profession:</strong> {fields && fields.parent_profession ? fields.parent_profession : 'N/A'}</p>

                                                </div>
                                            </Tab>
                                            <Tab eventKey="academic" title="ACADEMIC">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Institute</th>
                                                            <th scope="col">Degree</th>
                                                            <th scope="col">Start Year </th>
                                                            <th scope="col">End Year </th>
                                                            <th scope="col">Filed OF Study </th>                     
                                                            <th scope="col">Total Marks  </th>
                                                            <th scope="col">Obtained Marks </th>
                                                            <th scope="col">Division/Grade/CGPA </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {education && education?.map((edu, index) => (
                                                            <tr key={index}>
                                                                <th scope="row">{edu?.institute || 'N/A'}</th>
                                                                <td>{edu?.degree || 'N/A'}</td>
                                                                <td>{edu?.start_year || 'N/A'}</td>
                                                                <td>{edu?.end_year || 'N/A'}</td>
                                                                <td>{edu?.field_of_study || 'N/A'}</td>
                                                                <td>{edu?.total_marks || 'N/A'}</td>
                                                                <td>{edu?.obtained_marks || 'N/A'}</td>
                                                                <td>{edu?.cgpa || 'N/A'}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </Tab>
                                            <Tab eventKey="finance" title="FINANCE DETAIL">
                                                <div className="row">
                                                    <p className='col-6'><strong>Profession Of Father :</strong> {financedetail && financedetail.profession ? financedetail?.profession : 'N/A'}</p>
                                                    {/* <div className='col-6 row'> */}
                                                    <p className='col-6'><strong>Income (Annual) of Father/Guardian:</strong> {financedetail && financedetail.income ? financedetail?.income : 'N/A'}</p>
                                                    {/* </div> */}
                                                    {/* <div className='col-6 row'> */}

                                                    <p className='col-6'><strong>Are you getting any scholarship from any organization:</strong>  {financedetail && financedetail.scholarship ? financedetail.scholarship : 'N/A'}</p>
                                                    {/* </div> */}
                                                    <p className='col-6'><strong>Name of Organization:</strong> {financedetail && financedetail.organization ? financedetail.organization : 'N/A'}</p>
                                                   
                                                    <p className='col-6'><strong>Receiving Amount:</strong> {financedetail && financedetail.amount ? financedetail.amount : 'N/A'}</p>
                                                    <p className='col-6'><strong>Amount Received is :</strong> {financedetail && financedetail.payment_frequency ? financedetail.payment_frequency : 'N/A'}</p>

                                                </div>
                                            </Tab>
                                            <Tab eventKey="acedemic_uploads" title="ACADAMIC UPLOADS">
                                                <p><strong>Mark Sheet:</strong> <br />{acedemicuploads && acedemicuploads?.url ? 'Mark Sheet Attached' : 'N/A'}</p>

                                                <div>
                                                    {acedemicuploads?.url && <button type="button" className="btn btn-outline-primary m-1" onClick={() => window.open(`${acedemicuploads?.url}`, '_blank')}>View</button>}
                                                </div>

                                               
                                            </Tab>
                                            <Tab eventKey="resume" title="ACADAMIC UPLOADS">
                                                <p><strong>Atteseted Applicant CNIC Front:</strong> <br />{resume1 && resume1?.url ? 'Atteseted Applicant CNIC Front Attached' : 'N/A'}</p>

                                                <div>
                                                    {resume1?.url && <button type="button" className="btn btn-outline-primary m-1" onClick={() => window.open(`${resume1?.url}`, '_blank')}>View</button>}
                                                </div>

                                                <p><strong>Atteseted Applicant Cnic Back:</strong> <br />{resume2 && resume2?.url ? 'Atteseted Applicant Cnic Back Attached' : 'N/A'}</p>

                                              <div>
                                                  {resume2?.url && <button type="button" className="btn btn-outline-primary m-1" onClick={() => window.open(`${resume2?.url}`, '_blank')}>View</button>}
                                              </div>

                                              <p><strong>Student Id Card:</strong> <br />{resume3 && resume3?.url ? 'Student Id Card Attached' : 'N/A'}</p>

                                              <div>
                                                  {resume3?.url && <button type="button" className="btn btn-outline-primary m-1" onClick={() => window.open(`${resume3?.url}`, '_blank')}>View</button>}
                                              </div>
                                              <p><strong>Atteseted Father Guardian CNIC Front:</strong> <br />{resume4 && resume4?.url ? 'Atteseted Father Guardian CNIC Front Attached' : 'N/A'}</p>

                                              <div>
                                                  {resume4?.url && <button type="button" className="btn btn-outline-primary m-1" onClick={() => window.open(`${resume4?.url}`, '_blank')}>View</button>}
                                              </div>
                                              <p><strong>Atteseted Father Guardian Cnic Back:</strong> <br />{resume5 && resume5?.url ? 'Atteseted Father Guardian Cnic Back Attached' : 'N/A'}</p>

                                              <div>
                                                  {resume5?.url && <button type="button" className="btn btn-outline-primary m-1" onClick={() => window.open(`${resume5?.url}`, '_blank')}>View</button>}
                                              </div>
                                              <p><strong>Paid Fee Chalan:</strong> <br />{resume6 && resume6?.url ? 'Paid Fee Chalan Attached' : 'N/A'}</p>

                                              <div>
                                                  {resume6?.url && <button type="button" className="btn btn-outline-primary m-1" onClick={() => window.open(`${resume6?.url}`, '_blank')}>View</button>}
                                              </div>    
                                              <p><strong>Electricity Bill:</strong> <br />{resume7 && resume7?.url ? 'Electricity Bill Attached' : 'N/A'}</p>

                                              <div>
                                                  {resume7?.url && <button type="button" className="btn btn-outline-primary m-1" onClick={() => window.open(`${resume7?.url}`, '_blank')}>View</button>}
                                              </div>
                                            </Tab>
                                           
                                            <Tab eventKey="personalstatement" title="PERSONAL STATEMENT">
                                                <div className="row">
                                                    <p className='col-6'><strong>Personal Statement :</strong> {personalstatement && personalstatement.personal_statement ? personalstatement?.personal_statement : 'N/A'}</p>
                                                </div>
                                            </Tab>
                                           
                                            
                                        </Tabs>
                                    </SimpleCard>
                                </Col>
                            </Row>
                        </div>
                    )}
            </div>
            <NotificationContainer/>
        </div>
        
    );
};

export default ViewApplication;
